import { errorMessage } from "../../../../helpers/errorMessage";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useEffect, useState } from "react";

const FactureProductsTable = ({ products, DeleteProduct }) => {
  const [total, setTotal] = useState(0);
  useEffect(() => {
    let total = 0;
    products?.map((item) => {
      if (item.hasItems == true) {
        item.items.map((temp_item, i) => {
          total += parseInt(temp_item.purchasePrice) * parseInt(temp_item.qty);
        });
      } else {
        total += parseInt(item.purchasePrice) * parseInt(item.qty);
      }
      console.log(item);
      // total += item.purchasePrice;
    });
    setTotal(total);
  }, [products, DeleteProduct]);
  return (
    <div className="row">
      {products?.length == 0 ? (
        <div className="alert alert-info mt-2" role="alert">
          No Products
        </div>
      ) : (
        <div className="table-responsive">
          <table className="table table-hover">
            <thead>
              <tr>
                <th>Product</th>
                <th>Qty</th>
                <th>Purchase Price</th>
                <th>Total</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {products?.map((item, index) => {
                return (
                  <tr>
                    <th>{item.product_name}</th>
                    {item.hasItems == true ? (
                      <>
                        <th>
                          {item.items.map((temp_item, i) => {
                            return (
                              <>
                                <span key={i}>
                                  {temp_item.name + " =" + temp_item.qty}{" "}
                                </span>
                                <br />
                              </>
                            );
                          })}
                        </th>
                        <th>
                          {item.items.map((temp_item, i) => {
                            return (
                              <>
                                <span key={i}>
                                  {temp_item.purchasePrice} {"DA"}
                                </span>
                                <br />
                              </>
                            );
                          })}
                        </th>
                        <th>
                          {item.items.map((temp_item, i) => {
                            return (
                              <>
                                <span key={i}>
                                  {parseInt(temp_item.purchasePrice) *
                                    parseInt(temp_item.qty)}{" "}
                                  {"DA"}
                                </span>
                                <br />
                              </>
                            );
                          })}
                        </th>
                      </>
                    ) : (
                      <>
                        <th>{item.qty}</th>
                        <th>{item.purchasePrice} Da</th>
                        <th>
                          {parseInt(item.purchasePrice) * parseInt(item.qty)} Da
                        </th>
                      </>
                    )}
                    <th>
                      <div className="d-flex flex-row justify-between">
                        <Link onClick={(e) => DeleteProduct(index)}>
                          <FeatherIcon icon="trash" color="red" size={20} />
                        </Link>
                      </div>
                    </th>
                  </tr>
                );
              })}
              <tr>
                <th></th>
                <th></th>
                <th>Total</th>
                <th>{total} Da</th>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};
export default FactureProductsTable;
