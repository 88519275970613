import * as Yup from "yup";
import { parse } from "date-fns";

export const AddFactureProductSchema = Yup.object().shape({
  selectedProductId: Yup.string().required("Please select a product"),
  hasItems: Yup.boolean(),
  // qty: Yup.number().when("hasItems", {
  //   is: true,
  //   then: () =>
  //     Yup.number().max(5, "Must be 5 characters or less").required("Required"),
  //   otherwise: () => Yup.number().notRequired(),
  // }),
  // qty: Yup.number().when("hasItems", {
  //   is: false, // Condition: when hasItems is false
  //   then: number()
  //     .required("Quantity is required")
  //     .min(1, "Minimum quantity is 1"),
  //   otherwise: number().notRequired(), // No validation if condition is not met
  // }),
  // purchasePrice: Yup.number().when("hasItems", (hasItems, schema)=>{
  //   if(hasItems)
  //     return schema.required("Purchase price is required").min(1, "Minimum purchase price is 1")
  // }),
  // items: Yup.array()
  //   .of(
  //     Yup.object().shape({
  //       qty: Yup.number()
  //         .required("Product Variant quantity is required")
  //         .min(1, "Minimum quantity is 1"),
  //       purchasePrice: Yup.number()
  //         .required("Product Variant is required")
  //         .min(1, "Minimum price is 1"),
  //     })
  //   )
  //   .nullable(),
});
