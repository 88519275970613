import { useState, useEffect } from "react";
import Breadcrumb from "../../../components/common/Breadcrumb";
import Loader from "../../../components/common/general/Loader";
import useGoogleSheetsFunctions from "../../../hooks/app/google_sheets/useGoogleSheetsFunctions";
import { Link, useSearchParams } from "react-router-dom";
import Table from "../../../components/table/Table";
import useSearch from "../../../hooks/useSearch";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import AddSheetsDialog from "./AddSheetsDialog";
import { useGetGoogleAccount } from "../../../hooks/app/google_sheets/useGetGoogleAccount";
import Loading from "../../Loading";

const GoogleSheets = () => {
  const { getGoogleAuthLink, RegistreToken,LogOutAccountFunction } = useGoogleSheetsFunctions();

  const [page, setPage] = useState(1);
  const { query, search, setQuery } = useSearch();
  const { Datas, loadingDatas } = useGetGoogleAccount(page);
  const [ sended, setSended ] = useState(false);

  const [loading, setLoading] = useState(true);
  const [loadingLogout, setLoadingLogout] = useState(false);

  const [link, setLink] = useState("/google_sheets");

  const [searchParams, setSearchParams] = useSearchParams();

  if (searchParams.get("code")) {
    const token = searchParams.get("code");
    const state = searchParams.get("state");
    if(sended === false){
      RegistreToken(token,state,setSended);
    }
  }

  useEffect(() => {
    getGoogleAuthLink(setLoading, setLink);
  }, []);
  const columns = [
    {
      name: "Id",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Provider",
      selector: (row) => row.provider,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => {
        return (
          <span
            className={
              row?.status === true ? "badge bg-success" : "badge bg-danger"
            }
          >
            {row?.status === true ? "Active" : "Inactive"}
          </span>
        );
      },
    },
    {
      name: "created At",
      selector: (row) => row?.created_at,
      sortable: true,
    },
    {
      name: "Actions",
      selector: (row) => {
        return (
          <div className="d-flex flex-row justify-between">
            <Link
              to={`/google_sheets/view/${row.provider_id}`}
              title="View Google Account"
              className="me-3"
            >
              <FeatherIcon icon="eye"  color="green" size={20} />
            </Link>
            <Link title="Se Déconnecter" onClick={(e)=>{LogoutAccount(row.provider_id)}}>
              <FeatherIcon icon="log-out" color="red" size={20} />
            </Link>
          </div>
        );
      },
    },
  ];
  const LogoutAccount= (provider_id) => {
    LogOutAccountFunction(provider_id,setLoadingLogout);
  };
  return (
    <>
      <Breadcrumb
        title="Google Account"
        primaryLink="/"
        primaryTitle="Dashboard"
        thirdTitle="Google Account"
      />
      {loading === true ? (
        <Loading />
      ) : (
        <>
          {Datas?.data?.data.length === 0 ? (
            <div className="alert alert-warning" role="alert">
              Please Connect to your Google Account first
            </div>
          ) : null}
          <Table
            isLoading={loadingDatas}
            query={query}
            setQuery={setQuery}
            titleLabel={Datas?.data?.data.length < 1 ? "Google Account" : null}
            columns={columns}
            data={search(Datas?.data?.data, ["provider","email"], query)}
            meta={Datas?.data?.meta}
            setPage={setPage}
            btnFunction={false}
            labelLink={link}
          />
          {/* <AddSheetsDialog /> */}
        </>
      )}
    </>
  );
};
export default GoogleSheets;
