import { useNavigate } from "react-router-dom";
import { handleErrorApi } from "../../../helpers/handleErrorApi";
import Toast from "../../../helpers/toast.js";
import {
  getGoogleAuthLinkApi,
  getRegistreToken,
  getGoogleSheetsApi,
  getGoogleAccountApi,
  getSheetFilesApi,
  getSheetFileRowsApi,
  getCreateGoogleSheetApi,
  getGoogleSheetDetailsApi,
  getUpdateGoogleSheetApi,
  getGoogleLogoutApi
} from "../../../apis/app/google_sheets/getAllGoogleSheetsApis";

const useGoogleSheetsFunctions = () => {
  const navigate = useNavigate();
  const getGoogleAuthLink = async (setLoading, setLink) => {
    try {
      setLoading(true);
      const response = await getGoogleAuthLinkApi();
      setLink(response?.data);
    } catch (error) {
      setLoading(false);
      handleErrorApi(error?.response.status, error?.response.data.error);
    } finally {
      setLoading(false);
    }
  };
  const RegistreToken = async (token,state,setSended) => {
    try {
      const response = await getRegistreToken(token,state);
      Toast("success", "Successefly logged to your google Account");
      setSended(true);
    } catch (error) {
      handleErrorApi(error?.response.status, error?.response.data.message);
    }finally{
      navigate("/google_sheets/create");
      setSended(true);
    }
  };
  const getGoogleSheets = async (
    id,
    setLoadingGoogleSheets,
    setGoogleSheets
  ) => {
    try {
      setLoadingGoogleSheets(true);
      const response = await getGoogleSheetsApi(id);
      setGoogleSheets(response?.data?.data);
    } catch (error) {
      handleErrorApi(error?.response.status, error?.response.data.error);
      setLoadingGoogleSheets(false);
    } finally {
      setLoadingGoogleSheets(false);
    }
  };
  const getSheetFiles = async (
    google_account,
    sheet_id,
    setSheetFiles,
    setLoadingSheetFiles
  ) => {
    try {
      setLoadingSheetFiles(true);
      const response = await getSheetFilesApi(google_account, sheet_id);
      setSheetFiles(response?.data?.data);
    } catch (error) {
      handleErrorApi(error?.response.status, error?.response.data.error);
      setLoadingSheetFiles(false);
    } finally {
      setLoadingSheetFiles(false);
    }
  };
  const getGoogleAccount = async (
    setLoadingGoogleAccount,
    setGoogleAccount
  ) => {
    try {
      setLoadingGoogleAccount(true);
      const response = await getGoogleAccountApi();
      setGoogleAccount(response?.data?.data);
    } catch (error) {
      handleErrorApi(error?.response.status, error?.response.data.error);
      setLoadingGoogleAccount(false);
    } finally {
      setLoadingGoogleAccount(false);
    }
  };
  const getSheetFileRows = async (
    google_account,
    sheet_id,
    file_id,
    setSheetFiles,
    setLoadingSheetFiles
  ) => {
    try {
      setLoadingSheetFiles(true);
      const response = await getSheetFileRowsApi(
        google_account,
        sheet_id,
        file_id
      );
      setSheetFiles(response?.data?.data);
    } catch (error) {
      handleErrorApi(error?.response.status, error?.response.data.error);
      setLoadingSheetFiles(false);
    } finally {
      setLoadingSheetFiles(false);
    }
  };
  const CreateNewGoogleSheet = async (data, setLoading) => {
    try {
      setLoading(true);
      const response = await getCreateGoogleSheetApi(data);
      navigate('/google_sheets');
    } catch (error) {
      handleErrorApi(error?.response.status, error?.response.data.error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  const getUpdateGoogleSheet = async (id,data, setLoading)=>{
    try {
      setLoading(true);
      const response = await getUpdateGoogleSheetApi(id,data);
      navigate('/google_sheets');
      Toast('success',"Updated Successefully");
    } catch (error) {
      handleErrorApi(error?.response.status, error?.response.data.error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }
  const getGoogleSheetDetails = async (id,setLoading,setData)  =>{
    try {
      setLoading(true);
      const response = await getGoogleSheetDetailsApi(id);
      setData(response.data?.data);
    } catch (error) {
      handleErrorApi(error?.response.status, error?.response.data.error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }
  const LogOutAccountFunction = async (provider_id,setLoading)=>{
    try {
      setLoading(true);
      const response = await getGoogleLogoutApi(provider_id);
      navigate(0);
    } catch (error) {
      handleErrorApi(error?.response.status, error?.response.data.error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }
  return {
    getGoogleAuthLink,
    RegistreToken,
    getGoogleSheets,
    getGoogleAccount,
    getSheetFiles,
    getSheetFileRows,
    CreateNewGoogleSheet,
    getGoogleSheetDetails,
    getUpdateGoogleSheet,
    LogOutAccountFunction
  };
};
export default useGoogleSheetsFunctions;
