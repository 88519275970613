import axiosInstance from "../../Axios/axios";

const getAllFacturesApi = async (page) => {
  return await axiosInstance.get(`store/factures?page=${page}`);
};

const getAllSuppliersApi = async () => {
  return await axiosInstance.get(`store/factures/get_suppliers`);
};

const getStoreProductsApi = async () => {
  return await axiosInstance.get(`store/factures/products`);
};

const getCreateFactureApi = async (data) => {
  return await axiosInstance.post(`store/factures`, data);
};

const getFactureDetailsApi = async (id) => {
  return await axiosInstance.get(`store/factures/${id}`);
};
const getUpdateFactureAddLotApi = async (facture_id, data) => {
  return await axiosInstance.post(`store/factures/${facture_id}/item`, data);
};
const getDeleteFactureLotApi = async (facture_id, lot_id) => {
  return await axiosInstance.delete(
    `store/factures/${facture_id}/item/${lot_id}`
  );
};
const getUpdateFactureAddPaiementApi = async (facture_id, data) => {
  return await axiosInstance.post(`store/factures/${facture_id}/add_paiement`,data);
};
export {
  getAllFacturesApi,
  getAllSuppliersApi,
  getStoreProductsApi,
  getCreateFactureApi,
  getFactureDetailsApi,
  getDeleteFactureLotApi,
  getUpdateFactureAddLotApi,
  getUpdateFactureAddPaiementApi,
};
