import { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import useFactureFunctions from "../../../../hooks/app/Factures/useFactureFunctions";
import Loader from "../../../../components/common/general/Loader";
import Toast from "../../../../helpers/toast";

const UpdateFactureAddProductPopup = ({ onClose, facture_id, setData }) => { 
  const [loading, setLoading] = useState(false);
  const [loadingProduct, setLoadingProduct] = useState(false);
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const { getStoreProducts, getUpdateFactureAddLotFunction } =
    useFactureFunctions();
  useEffect(() => {
    getStoreProducts(setProducts, setLoadingProduct);
  }, []);
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
    register,
  } = useForm({
    defaultValues: {
      selectedProductId: "",
      hasItems: false,
      qty: null,
      purchasePrice: null,
      items: [],
    },
  });
  const selectedProductId = watch("selectedProductId");

  useEffect(() => {
    if (selectedProductId) {
      const selectedProduct = products.find((p) => p.id == selectedProductId);
      setValue("product_name", selectedProduct.name);
      setSelectedProduct(selectedProduct);
      setValue("hasItems", !!selectedProduct?.items?.length);
      if (selectedProduct?.items?.length) {
        setValue("items", selectedProduct.items);
        setValue("qty", null); // Reset qty for item-based products
        setValue("purchasePrice", null);
      } else {
        setValue("items", []); // Reset items for non-item products
      }
    } else {
      setSelectedProduct(null);
      setValue("hasItems", false);
      setValue("items", []);
      setValue("product_name", null);
    }
  }, [selectedProductId, products, setValue]);
  const onSubmit = (data) => {
    if (selectedProduct?.items?.length) {
      if (data.items.length === 0) {
        Toast("error", "Please select a a least one item");
      } else {
        const items = [];
        data.items.forEach((item) => {
          if (item.qty > 0 && item.purchasePrice > 0) {
            items.push(item);
          }
        });
        if (items.length === 0) {
          Toast("error", "Quantity and purchase price must be greater than 0");
        } else {
          const productToAdd = {
            product_id: selectedProductId,
            product_name: selectedProduct.name,
            purchasePrice: data.purchasePrice,
            qty: data.qty,
            hasItems: data.hasItems,
            items: items,
          };
          getUpdateFactureAddLotFunction(
            facture_id,
            productToAdd,
            setLoading,
            setData
          );
          setValue("selectedProductId", "");
          setValue("hasItems", false);
          setValue("items", []);
          setValue("product_name", null);
          onClose();
        }
      }
    } else {
      const productToAdd = {
        product_id: selectedProductId,
        product_name: selectedProduct.name,
        purchasePrice: data.purchasePrice,
        qty: data.qty,
        hasItems: data.hasItems,
        items: [],
      };
      getUpdateFactureAddLotFunction(
        facture_id,
        productToAdd,
        setLoading,
        setData
      );
      setValue("selectedProductId", "");
      setValue("hasItems", false);
      setValue("items", []);
      setValue("product_name", null);
      onClose();
    }
  };
  return (
    <>
      <div className="modal fade" tabIndex="-1" id="UpdateFactureProduct">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add Product To Facture</h5>
              <button
                type="button"
                className="btn-close"
                onClick={(e) => onClose(e)}
              ></button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="mb-2">
                  <label className="form-label">Product</label>
                  <Controller
                    name="selectedProductId"
                    control={control}
                    render={({ field }) => (
                      <select {...field} className="form-control">
                        <option value="">
                          {loadingProduct ? "Loading..." : "Select a product"}
                        </option>
                        {products.map((product) => (
                          <option key={product.id} value={product.id}>
                            {product.name}
                          </option>
                        ))}
                      </select>
                    )}
                  />
                  {errors.selectedProductId && (
                    <p className="text-danger mt-2 d-flex flex-row align-items-center">
                      {errors.selectedProductId.message}
                    </p>
                  )}
                </div>
                {selectedProduct && !selectedProduct.items?.length && (
                  <div className="mb-2">
                    <div>
                      <label className="form-label">Quantity</label>
                      <Controller
                        name="qty"
                        control={control}
                        render={({ field }) => (
                          <input
                            className="form-control"
                            type="number"
                            {...field}
                          />
                        )}
                      />
                      {errors.qty && (
                        <p className="text-danger mt-2 d-flex flex-row align-items-center">
                          {errors.qty.message}
                        </p>
                      )}
                    </div>
                    <div>
                      <label className="form-label">Purchase Price</label>
                      <Controller
                        name="purchasePrice"
                        control={control}
                        render={({ field }) => (
                          <input
                            className="form-control"
                            type="number"
                            {...field}
                          />
                        )}
                      />
                      {errors.purchasePrice && (
                        <p className="text-danger mt-2 d-flex flex-row align-items-center">
                          {errors.purchasePrice.message}
                        </p>
                      )}
                    </div>
                  </div>
                )}

                {selectedProduct && selectedProduct.items?.length > 0 && (
                  <div className="mb-2">
                    {selectedProduct.items.map((item, index) => (
                      <div key={item.id} className="row">
                        <input
                          type="text"
                          value={item.id}
                          {...register(`items.${index}.item_id`)}
                          hidden
                        />

                        <div className="col-lg-4">
                          <label className="form-label">Variant</label>
                          <input
                            className="form-control"
                            type="text"
                            defaultValue={item.name}
                            disabled
                          />
                        </div>
                        <div className="col-lg-4">
                          <label className="form-label">Quantity</label>
                          <Controller
                            name={`items.${index}.qty`}
                            control={control}
                            defaultValue={item.qty}
                            render={({ field }) => (
                              <input
                                className="form-control"
                                type="number"
                                {...field}
                              />
                            )}
                          />
                          {errors.items?.[index]?.qty && (
                            <p className="text-danger mt-2 d-flex flex-row align-items-center">
                              {errors.items[index].qty.message}
                            </p>
                          )}
                        </div>
                        <div className="col-lg-4">
                          <label className="form-label">Purchase Price</label>
                          <Controller
                            name={`items.${index}.purchasePrice`}
                            control={control}
                            defaultValue={item.purchasePrice}
                            render={({ field }) => (
                              <input
                                className="form-control"
                                type="number"
                                {...field}
                              />
                            )}
                          />
                          {errors.items?.[index]?.purchasePrice && (
                            <p className="text-danger mt-2 d-flex flex-row align-items-center">
                              {errors.items[index].purchasePrice.message}
                            </p>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={(e) => onClose(e)}
                  >
                    Close
                  </button>
                  {loading ? (
                    <button type="submit" className="btn btn-success me-2">
                      <Loader />
                    </button>
                  ) : (
                    <button type="submit" className="btn btn-success me-2">
                      Save
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default UpdateFactureAddProductPopup;
