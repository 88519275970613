import { getThemesApis } from "../../../apis/app/store/getThemesApis";
import { getDaysApi,updateStoreApi } from "../../../apis/app/store/getDaysApi";
import { getStoreApi } from "../../../apis/app/store/getStoreApi";
import { getCreateStoreApi } from "../../../apis/app/store/getCreateStoreApi";
import { handleErrorApi } from "../../../helpers/handleErrorApi";
import { useNavigate } from "react-router-dom";
import Toast from "../../../helpers/toast.js";

const StoreApis = () => {
  const navigate = useNavigate();

  const getThemes = async (setLodingThemes, setThemes) => {
    try {
      setLodingThemes(true);
      const response = await getThemesApis();
      setThemes(response?.data?.data);
    } catch (error) {
      setLodingThemes(false);
      handleErrorApi(error?.response.status, error?.response.data.error);
    } finally {
      setLodingThemes(false);
    }
  };
  const getDays = async (setLoadingDays, setDays) => {
    try {
      setLoadingDays(true);
      const response = await getDaysApi();
      setDays(response?.data?.data);
    } catch (error) {
      setLoadingDays(false);
      handleErrorApi(error?.response.status, error?.response.data.error);
    } finally {
      setLoadingDays(false);
    }
  };
  const createStore = async (data, setLoading) => {
    try {
      setLoading(true);
      const response = await getCreateStoreApi(data);
      Toast("success", "You have successfully create your store");
      navigate(0);
    } catch (error) {
      setLoading(false);
      handleErrorApi(error?.response.status, error?.response.data.message,error?.response.data.error);
    } finally {
      setLoading(false);
    }
  };
  const getStore = async (setLoading, setData,reset) => {
    try {
      setLoading(true);
      const response = await getStoreApi();
      reset(response?.data?.data);
      // setData(response?.data?.data);
    } catch (error) {
      setLoading(false);
      handleErrorApi(error?.response.status, error?.response.data.error);
    } finally {
      setLoading(false);
    }
  };
  const updateStore = async (data, setLoading,setData) => {
    try {
      setLoading(true);
      const response = await updateStoreApi(data);
      console.log(response);
      Toast("success", "You have successfully update your store");
    } catch (error) {
      setLoading(false);
      handleErrorApi(error?.response.status, error?.response.data.message,error?.response.data.error);
    } finally {
      setLoading(false);
    }
  };
  return {
    getThemes,
    createStore,
    getDays,
    getStore,
    updateStore
  };
};
export default StoreApis;
