import * as yup from "yup";

const phoneRegExp = /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/gm;
export const UpdateStoreSchema = yup.object().shape({
  name: yup.string().required("Store name is required"),
  theme_id: yup.string().required("Theme is required"),
  url: yup.string().required("Url is required"),
  phone: yup.string().matches(phoneRegExp, "Phone number is not valid"),
  email: yup.string().email().required("Email is required"),
  facebook: yup.string(),
  instagram: yup.string(),
  youtube: yup.string(),
  status: yup.boolean().typeError("Status is required"),
});
