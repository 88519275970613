import useAuth from "../../hooks/auth/useAuth";
import { useForm, FormProvider } from "react-hook-form";
import { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { ForgotPasswordSchema } from "../../validation/auth/ForgotPasswordSchema";
import { ResetPasswordSchema } from "../../validation/auth/ResetPasswordSchema";
import { Link } from "react-router-dom";
import RequestEmail from "./ForgotPassword/RequestEmail";
import NewPassword from "./ForgotPassword/NewPassword";

const ForgotPassword = () => {
  const { forgotPassword, resetPassword } = useAuth();
  const [step, setStep] = useState(0); 
  const [loading, setLoading] = useState(false);
  const methods = useForm({
    resolver: yupResolver(
      step === 0 ? ForgotPasswordSchema : ResetPasswordSchema
    ),
  });
  const [dataUser, setDataUser] = useState({
    first_name: "",
    last_name: "",
    email: "",
  });
  const { handleSubmit } = methods;

  const submitForgotPassword = async (data) => {
    forgotPassword(data, setStep, methods.setError, setLoading, setDataUser);
  };

  const submitResetPassword = async (data) => {
    resetPassword(data, methods.setError, setLoading);
  };

  return (
    <div className="main-wrapper">
      <div className="page-wrapper full-page">
        <div className="page-content d-flex align-items-center justify-content-center">
          <div className="row w-100 mx-0 auth-page">
            <div className="col-md-8 col-xl-7 mx-auto">
              <div className="card">
                <div className="row">
                  <div className="col-md-4 pe-md-0">
                    <div className="auth-side-wrapper"></div>
                  </div>
                  <div className="col-md-8 ps-md-0">
                    <div className="auth-form-wrapper px-4 py-5">
                      <p className="noble-ui-logo logo-light d-block mb-2">
                        Track <span>COD</span>
                      </p>
                      <h5 className="text-muted fw-normal mb-4">
                        Welcome back! Reset your password.
                      </h5>
                      <FormProvider {...methods}>
                        {step === 0 ? (
                          <RequestEmail
                            setStep={setStep}
                            loading={loading}
                            onSubmit={handleSubmit(submitForgotPassword)}
                          />
                        ) : (
                          <NewPassword
                            setStep={setStep}
                            dataUser={dataUser}
                            loading={loading}
                            onSubmit={handleSubmit(submitResetPassword)}
                          />
                        )}
                      </FormProvider>
                      <Link to="/signup" className="d-block mt-3 text-muted">
                        Don't have account? SignUp Now
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ForgotPassword;
