import { useParams } from "react-router-dom";
import Breadcrumb from "../../../components/common/Breadcrumb";
import { useState, useEffect } from "react";
import useCreateCategory from "../../../hooks/app/categories/useCreateCategory";
import { useForm } from "react-hook-form";
import Toast from "../../../helpers/toast.js";
import Loader from "../../../components/common/general/Loader";
import InputComponent from "../../../components/FormUpdate/InputComponent";
import SelectComponent from "../../../components/FormUpdate/SelectComponent";
import TextareaComponent from "../../../components/FormUpdate/TextareaComponent";
import ImageDropzone from "../../../components/common/general/ImageDropzone";
import Modal from "../../../components/Modal/Modal.jsx";
import { errorMessage } from "../../../helpers/errorMessage.js";
import { yupResolver } from "@hookform/resolvers/yup";
const UpdateCategory = () => {
  const { id } = useParams();
  const [loadingData, setLoadingData] = useState(false);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const { GetCategory, UpdateCategory } = useCreateCategory();
  useEffect(() => {
    GetCategory(id, setLoadingData, setData,reset);
  }, [id]);
  const {
    control,
    setValue,
    register,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: data,
  });
  const photo = watch("image");
  const onSubmit = (data) => {
    if (
      data.name == undefined &&
      data.description == undefined &&
      data.status == undefined &&
      data.image == undefined
    ) {
      Toast("error", "Nothing to Update");
    } else {
      UpdateCategory(
        {
          ...data,
        },
        setLoading,
        id,
        setData
      );
    }
  };
  return (
    <>
      <Breadcrumb
        title="Categories"
        primaryLink="/"
        primaryTitle="Dashboard"
        thirdTitle={loadingData ? "Update Category" : data?.name}
        secondaryLink="/categories"
        secondarytitle="Categories"
      />
      {loadingData ? (
        <Loader />
      ) : (
        <div className="card">
          <div className="card-body">
            <form className="forms-sample" onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-lg-6">
                  <InputComponent
                    errors={errors}
                    inputType="text"
                    label="Category Name"
                    name="name"
                    placeholder="Category name"
                    control={control}
                    defaultValue={data?.name}
                    error="name"
                  />
                </div>
                <div className="col-lg-6">
                  <div>
                    <label for="status" className="form-label">
                      Status
                    </label>
                    <select
                      name="status"
                      id="status"
                      className="form-control mt-1"
                      {...register("status")}
                    >
                      <option value="">Selectionner</option>
                      <option value={true}>Active</option>
                      <option value={false}>Disable</option>
                    </select>
                  </div>
                  {errorMessage(errors, "status")}
                </div>
                <div className="col-lg-6">
                  <TextareaComponent
                    defaultValue={data?.description}
                    name="description"
                    control={control}
                    error="description"
                    errors={errors}
                    label="Description"
                  />
                </div>
                <div className="col-lg-6">
                  <div className="form-group mt-1">
                    <ImageDropzone
                      placeholder="Drag and drop or click to select image"
                      photos={photo}
                      name="image"
                      setValue={setValue}
                      multiple={false}
                      previewCol={4}
                      errors={errors}
                    />
                  </div>
                </div>
              </div>
              {loading ? (
                <button type="submit" className="btn btn-primary me-2">
                  <Loader />
                </button>
              ) : (
                <button type="submit" className="btn btn-success me-2">
                  Update Category
                </button>
              )}
            </form>
          </div>
          <Modal modalId="UpdateCat" />
        </div>
      )}
    </>
  );
};
export default UpdateCategory;
