import axiosInstance from "../../Axios/axios";

const getDaysApi = async () => {
  return await axiosInstance.get(`store/store/days`);
};
const updateStoreApi = async (data) => {
  return await axiosInstance.post(`store/store/update`,data);
};
export {
  getDaysApi,
  updateStoreApi
};