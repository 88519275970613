export const openPopup = (id) => {
  const element = document.getElementById(id);
  element.classList.add("show");
  element.style.display = "block";
};
export const closePopup = (id) => {
    const element = document.getElementById(id);
    element.classList.remove("show");
    element.style.display = "none";
  };
