export const CreateAgentSetErrors = (data, setError) => {
    if (data.hasOwnProperty("username")) {
      data.username.map((item) => {
        setError("username", {
          type: "submit",
          message: item,
        });
      });
    }
    if (data.hasOwnProperty("last_name")) {
      data.last_name.map((item) => {
        setError("last_name", {
          type: "submit",
          message: item,
        });
      });
    }
    if (data.hasOwnProperty("email")) {
      data.email.map((item) => {
        setError("email", {
          type: "submit",
          message: item,
        });
      });
    }
    if (data.hasOwnProperty("phone")) {
      data.phone.map((item) => {
        setError("phone", {
          type: "submit",
          message: item,
        });
      });
    }
  };
  