import { handleErrorApi } from "../../../helpers/handleErrorApi";
import { getCreateAgentsApi } from "../../../apis/app/agents/getAgentsApis";
import { useNavigate } from "react-router-dom";
import Toast from "../../../helpers/toast.js";
import { CreateAgentSetErrors } from "../../../helpers/HandleSetErrors/CreateAgentSetErrors.js";

const useAgentsFunctions = () => {
    const navigate = useNavigate();

    const CreateAgent = async (data, setLoading,setError) => {
        try {
            setLoading(true);
            const response = await getCreateAgentsApi(data);
            Toast("success", "You have successfully create agent");
            navigate("/users/agents");
          } catch (error) {
            setLoading(false);
            // handleErrorApi(error?.response.status, error?.response.data.message,error?.response.data.error);
            if (error?.response.status === 400) {
                    const data = error?.response.data.error;
                    CreateAgentSetErrors(data, setError);
                  }
          } finally {
            setLoading(false);
          }
    }
    return {
        CreateAgent,
    };
}
export default useAgentsFunctions;