export default function SomeWilayaUpdate ({ items, register, new_data, wilayas }) {
  return (
    <>
      {new_data === false ? (
        <>
          {items?.map((item, index = 1) => {
            return (
              <div className="perfect-scrollbar-example">
                <div className="row" key={index}>
                  <div className="col-lg-6">
                    <div className="mb-3">
                      <input
                        type="text"
                        className="form-control"
                        autocomplete="off"
                        placeholder="Wilaya name"
                        disabled
                        value={item?.wilaya?.name}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Discount"
                      defaultValue={item?.discount}
                      {...register("wilaya_" + item.wilaya?.id)}
                      //   {...register(`items.${index}.discount`)}
                    />
                    {/* {errorMessage(errors, "name")} */}
                  </div>
                </div>
              </div>
            );
          })}
        </>
      ) : (
        <>
          {wilayas?.map((item, index) => {
            return (
              <div className="perfect-scrollbar-example">
                <div className="row" key={index}>
                  <div className="col-lg-6">
                    <div className="mb-3">
                      <input
                        type="text"
                        className="form-control"
                        autocomplete="off"
                        placeholder="Wilaya name"
                        disabled
                        value={item?.name}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Discount"
                      defaultValue={0}
                      {...register("wilaya_" + item.id)}
                    />
                    {/* {errorMessage(errors, "name")} */}
                  </div>
                </div>
              </div>
            );
          })}
        </>
      )}
    </>
  );
};