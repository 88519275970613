import Toast from "../../../helpers/toast";

export const SelectedProduct = (
  products,
  selectedProductId,
  setValue,
  setSelectedProduct
) => {
  if (selectedProductId) {
    const selectedProduct = products.find((p) => p.id == selectedProductId);
    setValue("product_name", selectedProduct.name);
    setSelectedProduct(selectedProduct);
    setValue("hasItems", !!selectedProduct?.items?.length);
    if (selectedProduct?.items?.length) {
      setValue("items", selectedProduct.items);
      setValue("qty", null); // Reset qty for item-based products
    } else {
      setValue("items", []); // Reset items for non-item products
    }
  } else {
    setSelectedProduct(null);
    setValue("hasItems", false);
    setValue("items", []);
    setValue("product_name", null);
  }
};

export const onSubmitFunction = (
  data,
  selectedProduct,
  selectedProductId,
  getLeadAddProducts,
  id,
  setLoadingCheck,
  setData
) => {
  if (selectedProduct?.items?.length) {
    if (data.items.length == 0) {
      Toast("error", "Please select a a least one item");
    } else {
      const items = [];
      data.items.forEach((item) => {
        if (item.qty > 0 && item.qty <= item.qty_reste) {
          items.push(item);
        }
      });
      if (items.length === 0) {
        Toast(
          "error",
          "Quantity for variants must be greater than 0, and less than or equal to the available quantity"
        );
      } else {
          const productToAdd = {
            product_id: selectedProductId,
            product_name: selectedProduct.name,
            qty: data.qty,
            hasItems: data.hasItems,
            items: items,
          };
          getLeadAddProducts(id, productToAdd, setLoadingCheck, setData);
      }
    }
  } else {
    if (data.qty != null && data.qty != "" && data.qty != 0) {
      if (data.qty > selectedProduct.qty_reste) {
        Toast(
          "error",
          "Quantity entered is greater than the available quantity"
        );
      } else {
        const productToAdd = {
          product_id: selectedProductId,
          product_name: selectedProduct.name,
          qty_reste: selectedProduct.qty_reste,
          qty: data.qty,
          hasItems: data.hasItems,
          items: [],
        };
        getLeadAddProducts(id, productToAdd, setLoadingCheck, setData);
      }
    } else {
      Toast("error", "Min Quantity is 1");
    }
  }
};
