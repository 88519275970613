import { toast, Toaster } from "react-hot-toast";

const Toast = (type, message) => {
  // return (
  //   <>
  //     <Toaster position="top-right" reverseOrder={true} />
  //   </>
  // );
  return toast[type](message);
};

export default Toast;
