import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import StoreApis from "../../../hooks/app/store/store";
import { errorMessage } from "../../../helpers/errorMessage";
import Loader from "../../../components/common/general/Loader";
import Breadcrumb from "../../../components/common/Breadcrumb";
import InputComponent from "../../../components/FormUpdate/InputComponent";
import { UpdateStoreSchema } from "../../../validation/app/store/UpdateStoreSchema";

const MyStore = () => {
  const { getThemes, getStore, updateStore } =
    StoreApis();
  const [themes, setThemes] = useState([]);
  const [loadingThemes, setLodingThemes] = useState(false);
  const [loading, setLoading] = useState(false);
  const [Data, setData] = useState({});
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: Data,
    resolver: yupResolver(UpdateStoreSchema),
  });
  useEffect(() => {
    getThemes(setLodingThemes, setThemes);
    getStore(setLoading, setData,reset);
  }, []);
  const onSubmit = (data) => {
    updateStore(data, setLoading, setData);
  };
  return (
    <>
      <Breadcrumb
        title="My Store"
        primaryLink="/"
        primaryTitle="Dashboard"
        thirdTitle="My Store"
      />
      {loading ? (
        <Loader />
      ) : (
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <form
                  className="forms-sample"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="row">
                    <div className="col-lg-4">
                      <InputComponent
                        errors={errors}
                        inputType="text"
                        label="Store Name"
                        name="name"
                        placeholder="Store name"
                        control={control}
                        defaultValue={Data?.name}
                        error="name"
                      />
                    </div>
                    <div className="col-lg-4">
                      <div>
                        <label for="theme_id" className="form-label">
                          Themes
                        </label>
                        <select
                          name="theme_id"
                          id="theme_id"
                          className="form-control mt-1"
                          {...register("theme_id")}
                        >
                          <option value="">Selectionner</option>
                          {themes?.map((item, index) => {
                            return (
                              <option key={index} value={item?.id}>
                                {item?.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      {errorMessage(errors,"theme_id")}
                    </div>
                    <div className="col-lg-4">
                      <div>
                        <label for="status" className="form-label">
                          Status
                        </label>
                        <select
                          name="status"
                          id="status"
                          className="form-control mt-1"
                          {...register("status")}
                        >
                          <option value="">Selectionner</option>
                          <option value={true}>Active</option>
                          <option value={false}>Disable</option>
                        </select>
                      </div>
                      {errorMessage(errors,"status")}
                    </div>
                    <div className="col-lg-4">
                      <InputComponent
                        errors={errors}
                        inputType="text"
                        label="Email"
                        name="email"
                        placeholder="Email"
                        control={control}
                        defaultValue={Data?.email}
                        error="email"
                      />
                    </div>
                    <div className="col-lg-4">
                      <InputComponent
                        errors={errors}
                        inputType="text"
                        label="phone"
                        name="phone"
                        placeholder="phone"
                        control={control}
                        defaultValue={Data?.phone}
                        error="phone"
                      />
                    </div>
                    <div className="col-lg-4">
                      <InputComponent
                        errors={errors}
                        inputType="text"
                        label="url"
                        name="url"
                        placeholder="url"
                        control={control}
                        defaultValue={Data?.url}
                        error="url"
                      />
                    </div>
                    <div className="col-lg-4">
                      <InputComponent
                        errors={errors}
                        inputType="text"
                        label="facebook"
                        name="facebook"
                        placeholder="facebook"
                        control={control}
                        defaultValue={Data?.facebook}
                        error="facebook"
                      />
                    </div>
                    <div className="col-lg-4">
                      <InputComponent
                        errors={errors}
                        inputType="text"
                        label="instagram"
                        name="instagram"
                        placeholder="instagram"
                        control={control}
                        defaultValue={Data?.instagram}
                        error="instagram"
                      />
                    </div>
                    <div className="col-lg-4">
                      <InputComponent
                        errors={errors}
                        inputType="text"
                        label="youtube"
                        name="youtube"
                        placeholder="youtube"
                        control={control}
                        defaultValue={Data?.youtube}
                        error="youtube"
                      />
                    </div>
                  </div>
                  {loading ? (
                    <button type="submit" className="btn btn-primary me-2 mt-2">
                      <Loader />
                    </button>
                  ) : (
                    <button type="submit" className="btn btn-success me-2 mt-2">
                      Update Store
                    </button>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default MyStore;
