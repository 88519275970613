import { ConfirmeAccountApi } from "../../../apis/app/user/ConfirmeAccountApi.js";
import { ResendCodeConfirmationApi } from "../../../apis/app/user/ResendCodeConfirmationApi.js";
import Toast from "../../../helpers/toast.js";
import { handleErrorApi } from "../../../helpers/handleErrorApi.js";
import { useNavigate } from "react-router-dom";

const useConfirmeAccount = () => {
  const navigate = useNavigate();
    const ConfirmeAccount = async (data, setLoading) => {
        try {
          setLoading(true);
          const response = await ConfirmeAccountApi(data);
          // Toast("success", "You have successfully confirme your account");
          setLoading(false);
          navigate(0);
        } catch (error) {
          setLoading(false);
          handleErrorApi(error?.response.status, error?.response.data.error);
        }
      };
      const ResendCode = async (setLoadingSend,setCounter) =>{
        try{
          setLoadingSend(true);
          const response = await ResendCodeConfirmationApi();
          Toast("success", response.data?.message);
          setCounter(60);
          setLoadingSend(false);
        }catch(error) {
          setLoadingSend(false);
          handleErrorApi(error?.response.status, error?.response.data.error);
        }
      }
      return {
        ConfirmeAccount,
        ResendCode
      };
}
export default useConfirmeAccount;